import * as React from "react"

import Layout from "../components/Layout"

/**
 * HTML
 */
const FourOhFour = () => (
  <React.Fragment>
    <Layout title = "404" />
  </React.Fragment>
)

export default FourOhFour